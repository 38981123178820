<template>
  <div>
    <v-container v-if="usersSet" class="text-center">
      <div :class="checkResult !== null ? (checkResult ? 'jumping' : 'shaking') : null" class="mt-10 my-15">
        <user-avatar :key="user.id" :user="user" x-large/>
      </div>
      <div style="width: 70vw; max-width: 300px; margin: 0 auto">
        <div
          v-for="item in usersSet" :key="item.id"
        >
          <v-btn
            :color="checkId && checkId === item.id && checkResult !== null ? (checkResult ? 'success' : 'error') : null"
            :disabled="checkId && checkId !== item.id"
            block
            outlined
            class="mb-5"
            @click="check(item)"
          >
            {{ item.name }}
          </v-btn>
        </div>
      </div>
      <div class="mt-10 text-overline">
        Richtig: <b>{{ correctCount }}</b>
      </div>
    </v-container>
  </div>
</template>

<script>
import UserAvatar from '@/components/base/UserAvatar'

export default {
  name: 'UsersquizView',
  components: { UserAvatar },
  data () {
    return {
      usersSet: null,
      usersIdx: 0,
      checkId: null,
      checkResult: null,
      correctCount: 0
    }
  },
  computed: {
    users () {
      return Object.keys(this.$root.users)
        .map(userId => ({ id: userId, ...this.$root.users[userId] }))
        .filter(user => user.name && user.img?.src && user.sex && !user._disabled) // only those with name, img and sex, remove disabled
    },
    user () {
      return this.usersSet && this.usersSet.length > this.usersIdx ? this.usersSet[this.usersIdx] : null
    }
  },
  methods: {
    loadSet () {
      if (this.users.length < 3) {
        console.error('Quiz: not enough users!')
        return
      }
      const usersSet = []
      const idx = Math.floor(Math.random() * this.users.length)
      usersSet.push(this.users[idx])
      const others = this.users.filter(u => /*!this.users[idx].sex || !u.sex ||*/ this.users[idx].sex === u.sex)
      if (others.length < 2) {
        console.error('Quiz: not enough (same sex) users!')
        return
      }
      while (usersSet.length < 3) {
        const idx = Math.floor(Math.random() * others.length)
        if (!usersSet.find(u => u.id === others[idx].id)) {
          usersSet.push(others[idx])
        }
      }
      this.usersSet = this.shuffleArray(usersSet)
      this.usersIdx = Math.floor(Math.random() * 3)
    },
    shuffleArray (array) {
      return array.map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    },
    check (user) {
      if (this.checkId !== null) return
      this.checkId = user.id
      if (user.id === this.user.id) {
        this.checkResult = true
        this.correctCount++
      } else {
        this.checkResult = false
        this.correctCount = 0
      }
      setTimeout(() => {
        if (this.checkResult) {
          this.loadSet()
        }
        this.checkId = null
        this.checkResult = null
      }, 1000)
    }
  },
  watch: {
    users () {
      this.loadSet()
    }
  },
  created () {
    this.loadSet()
  }
}
</script>

<style scoped>
.jumping {
  animation-name: jump;
  animation-duration: 1s;
  animation-timing-function: ease;
}
@keyframes jump {
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(-10px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-2px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}

.shaking {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-5px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(5px, 0, 0);
  }
}
</style>